/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components

// prop-types is a library for typechecking of props

// @mui material components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React components
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function LoadingNavbar() {
  return (
    <MKBox sx={{ mx: 6, mt: 2 }}>
      <MKBox>
        <Skeleton height={50} width="100%" />
      </MKBox>
      <MKBox sx={{ mt: 16 }}>
        <Skeleton height={40} width="100%" />
      </MKBox>
      <MKBox sx={{ mt: 2 }}>
        <Skeleton height={20} width="100%" count={2} />
      </MKBox>
      <MKBox sx={{ mt: 4 }}>
        <Skeleton height={200} width="100%" />
      </MKBox>
      <MKBox sx={{ mt: 10 }}>
        <Skeleton height={20} width="100%" count={10} />
      </MKBox>
    </MKBox>
  );
}

export default LoadingNavbar;
